
<template>
  <div>
    <el-dialog
      style="text-align: center;"
      width="50%"
      top="30vh"
      :visible.sync="dialogVisible"
      @close="onCloseDialog"
      >
        <div  v-if="isShowConfirmPopup && dialogVisible">
          <h3>Transaction completed. Token is burned</h3>
        <div slot="footer" class="dialog-footer">
          <el-button
            round
            type="primary"
            style="min-width: 100px"
            size="small" @click="completeSuccessAndCloseDialog">Close</el-button>
        </div>
        </div>
      <div v-if="!isShowConfirmPopup && dialogVisible" >
        <h3>Confirm to complete the transaction?</h3>
        <span style="color:red">*Please ensure that you have received the full amount from the paying party. Completed transactions cannot be undone.</span>
        <div slot="footer" class="dialog-footer">
          <el-button
            round
            type="second"
            style="min-width: 100px"
            size="small"
            @click="onCloseDialog">Cancel</el-button>
          <el-button
            round
            type="primary"
            style="min-width: 100px"
            size="small" @click="handleComplete">Confirm</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { completeSingleFinance, } from '@/services/modules/finance.js'
export default {
  name: 'DialogCompleteFinance',
  props: {
    dialogVisible: {
      type: Boolean,
      required: true
    },
    entityData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isShowConfirmPopup: false
    }
  },
  methods: {
    onCloseDialog () {
      this.$emit('onCloseFinancerDialog')
      this.isShowConfirmPopup = false
    },
    completeSuccessAndCloseDialog () {
      this.onCloseDialog()
    },
    handleComplete () {
      this.isShowConfirmPopup = true
      completeSingleFinance(this.entityData.id).then(resp => {
        if (resp.code === 1000) {
          this.$emit('getSubmitRespMessage')
        } else {
          this.$emit('getSubmitRespMessage', resp.message)
        }
      })
    }

  }
}
</script>
<style lang="scss" scoped>
.dialog-footer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
}
.el-message-box {
  text-align: center !important;
  /deep/ .el-message-box__btns {
    text-align: center !important;
  }
}

</style>
