var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: { width: "50%", top: "30vh", visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.onCloseDialog,
          },
        },
        [
          _vm.isShowConfirmPopup && _vm.dialogVisible
            ? _c("div", [
                _c("h3", [_vm._v("Transaction completed. Token is burned")]),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "min-width": "100px" },
                        attrs: { round: "", type: "primary", size: "small" },
                        on: { click: _vm.completeSuccessAndCloseDialog },
                      },
                      [_vm._v("Close")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          !_vm.isShowConfirmPopup && _vm.dialogVisible
            ? _c("div", [
                _c("h3", [_vm._v("Confirm to complete the transaction?")]),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v(
                    "*Please ensure that you have received the full amount from the paying party. Completed transactions cannot be undone."
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "min-width": "100px" },
                        attrs: { round: "", type: "second", size: "small" },
                        on: { click: _vm.onCloseDialog },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "min-width": "100px" },
                        attrs: { round: "", type: "primary", size: "small" },
                        on: { click: _vm.handleComplete },
                      },
                      [_vm._v("Confirm")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }